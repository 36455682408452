import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';

import Footer from "./components/Footer";

import HomePage from './pages/HomePage';
import FaqPage from './pages/FaqPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4D3922ff', // caf-noir
        },
        secondary: {
            main: '#9F885Bff', // lion
        },
        background: {
            default: '#FDFCE9ff', // ivory
            paper: '#302F11ff', // drab-dark-brown
        },
        text: {
            primary: '#FDFCE9ff', // ivory (lighter text for better contrast on dark backgrounds)
            secondary: '#FFF8DCff', // corn-silk (slightly lighter than lion for secondary text)
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h1: {
            color: '#FDFCE9ff', // ivory (bright for better visibility)
        },
        body1: {
            color: '#FFF8DCff', // corn-silk for readable body text on darker backgrounds
        },
        body2: {
            color: '#E0D9B9ff', // light taupe for additional contrast in secondary areas
        },
    },
});

function App() {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/about" element={<FaqPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    );
  }

export default App;