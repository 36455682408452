import React from 'react';
import HeroImage from "../components/HeroImage";
import Introductory from '../components/Introductory';
import ReviewsShowcase from '../components/ReviewShowcase';
import { CssBaseline } from '@mui/material';

function HomePage() {
  return (
      <CssBaseline>
          <HeroImage text="Welcome to angolozok.hu"/>
          <Introductory />
          <ReviewsShowcase />
      </CssBaseline>  
  );
}

export default HomePage;
