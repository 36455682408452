import React, { useState } from 'react';
import { Box, Typography, Grid, Avatar, Button, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';


const reviews = [
    {
        name: "Viki, Gimnazista",
        image: "." + process.env.PUBLIC_URL + '/viki.jpg',
        text: "„Én 100%-osan meg vagyok elégedve az angol órákkal. Amikor elkezdtem járni, megszólalni is alig\n" +
            "bírtam angolul, állandóan leblokkoltam. Fél évvel később sikerült közép szinten előrehozott\n" +
            "érettségiznem 5-ösre a nyelvből, majd a következő vizsgaidőszakban emelt szinten szintén 5-ösre.\n" +
            "Jelenleg felsőfokú nyelvvizsgára készülök, úgy érzem eredményesen. Mindenkinek bátran ajánlom\n" +
            "Attilát!”"
    },
    {
        name: "Lilla, Egyéni Vállalkozó",
        image: "./jane-avatar.jpg",
        text: "„Amit én kiemelnék Attila óráiból például, hogy Ő tényleg teljesen személyre szabottan tartja az\n" +
            "órákat, ami a az egyén fejlődésének fő szempontja. Rendkívül hasznos, hogy magyarul szinte\n" +
            "egyáltalán nem kommunikáltunk, még mikor telefonon beszéltünk sem, és a heti rendszeresség miatt\n" +
            "tényleg szó szerint rá voltam kényszerülve, hogy használjam az angolt, ami rohamos fejlődést\n" +
            "eredményezett. Illetve számomra nagyon sokat számított, hogy Attila mennyire rugalmasan állt az\n" +
            "órák időpontjához, ez nagyban megkönnyítette számomra a tanulást a zsúfolt és rendszertelen\n" +
            "időbeosztásom mellett.”"
    },
    {
        name: "Csaba, Biztonsági Vezető",
        image: "„./michael-avatar.jpg",
        text: "Nehéz elfogulatlan véleményt írnom, mert nem csak egy nagyszerű embert, egy felkészült tanárt,\n" +
            "hanem egy nagyon jó barátot is találtam Attila személyében. Mindenkinek ajánlom a kezdőtől a\n" +
            "legmagasabb szintig.”"
    },
    {
        name: "Donkó Rleone Attila",
        image: "." + process.env.PUBLIC_URL + '/attila.jpg',
        text: "„Attila vagyok. 44 éves. Most épp villanyszerelő és PLC programozó. Kicsit későn érintett meg a\n" +
            "nyelvtanulás szele. Úgy 8 éve. Általánosban oroszt tanultam, középiskolában angolt (kellett volna), de\n" +
            "sokkal jobban érdekelt más. Sikeresen eljutottam iskoláim befejeztével az “I am”-ig. Majd egy külföldi\n" +
            "horgászkirándulás alkalmával rájöttem arra, hogy tök égő vagyok, mivel egy szót sem beszélek\n" +
            "angolul. Először autodidakta módon fejlesztettem tudásom, majd mikor éreztem, hogy kell valami\n" +
            "plusz, akkor kerestem nyelvtanárt. Egy barátom ajánlotta Attilát, aki elsőre szimpi volt. Ha jól\n" +
            "emlékszem, 4 éve járok hozzá. Főleg beszélgetünk. Nagyon sokat jelent a fejlődésben. Rengeteget\n" +
            "röhögünk és felettébb viccesen, könnyeden telnek el az órák. Azóta mondhatom, barátomnak is\n" +
            "tekinthetem, hisz sok olyan témát is megbeszéltünk, amit csak a barátaival oszt meg az ember. Azóta\n" +
            "felépített “nyelvtudásommal” megjártam Los Angelest, Las Vegast, és sok európai országot és nem\n" +
            "vallottam szégyent. Soha nem késő nyelvet tanulni! Édesanyám 66 és megfertőztem. Naponta tanul\n" +
            "keményen. Természetesen meleg szívvel ajánlom Attilát, hiszen ő billentett át engem arra az oldalra,\n" +
            "hogy merjek beszélni. Ha néha helytelenül is, de merjek. Sokkal nyitottabbak azok az emberek akinek\n" +
            "\n" +
            "a beszélt nyelvén, legfőképp anyanyelvén szólal meg valaki. Le a kézzel-lábbal mutogatással!\n" +
            "Menjetek Attilához!”"
    },
    {
        name: "Anna, Jogász",
        image: "./michael-avatar.jpg",
        text: "Lengyel Attila szerettette meg velem az angol nyelvet, mert jó pedagógiai érzékkel választotta meg az érdeklődésemnek megfelelő tanítási módszert. Szívesen mentem az óráira, jó hangulatban, ösztönző környezetben tanított, tanultam. Nála sikeresnek érezhetem magam az angol nyelvi órákon."
    },
    {
        name: "Tamás,\n" +
            "Villamosmérnök",
        image: "." + process.env.PUBLIC_URL + '/tamas.jpg',
        text: "A Cambridge English: First (FCE) nyelvvizsgára való felkészüléskor kerestem fel Attilát, hogy segítsen\n" +
            "a felkészülésben. A hangsúlyt az órák során a szóbeli kommunikációra helyeztük, mert ennek\n" +
            "gyakorlására nyújtanak kevesebb lehetőséget a középiskolai angol órák. Ez sokat segített abban, hogy\n" +
            "könnyebben és folyékonyabban tudjam magam szóban kifejezni, viszont a felkészülés során\n" +
            "végigvettük az összes nyelvtani részt is, és ezeket részletesen elmagyarázta, valamint házi feladatok\n" +
            "formájában gyakorolni is tudtam a nyelvtani részt is. Összességében azt gondolom, hogy a\n" +
            "nyelvvizsgára való sikeres felkészítésen túl olyan alapokat tudtunk együtt kialakítani az angol\n" +
            "tudásomban, amelyre a későbbiekben a karrierem során is tudtam és tudok támaszkodni."
    },
];


const ReviewShowcase = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedReviews, setExpandedReviews] = useState({});
    const theme = useTheme();

    // Use media query to determine if the screen width is mobile or tablet-sized
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Determine how many reviews to show based on screen size
    const reviewsToShow = isMobile ? 1 : 3;
    const visibleReviews = reviews.slice(currentIndex, currentIndex + reviewsToShow);

    // Swipe handlers for mobile
    const handleNext = () => {
        if (currentIndex + reviewsToShow < reviews.length) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    // Swipeable config for mobile
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,  // Enable swipe detection via mouse (optional for testing on PC)
    });

    const toggleExpand = (id) => {
        setExpandedReviews((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // Toggle the expansion state for the review with the given id
        }));
    };

    const truncateText = (text, isExpanded) => {
        const limit = 100; // Character limit before truncation
        if (isExpanded || text.length <= limit) {
            return text;
        }
        return text.slice(0, limit) + '...';
    };

    return (
        <Box
            sx={{
                maxWidth: '1000px',
                margin: '40px auto',
                textAlign: 'center',
                position: 'relative',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FDFCE9ff', // Light background color for the box
            }}
            {...(isMobile ? swipeHandlers : {})}  // Apply swipe handlers only on mobile
        >
            <Typography sx={{ fontSize: "25px", fontWeight: "bold", color: "black", padding: "20px" }}>
                Mit Gondolnak a Diákok
            </Typography>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {/* Arrow Left (only show on larger screens) */}
                {!isMobile && (
                    <Grid item xs={1}>
                        <IconButton
                            onClick={handlePrev}
                            disabled={currentIndex === 0}
                            sx={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
                        >
                            <ArrowBack />
                        </IconButton>
                    </Grid>
                )}

                {/* Review Items */}
                <Grid item xs={isMobile ? 12 : 10}>
                    <Grid container spacing={4} justifyContent="center">
                        {visibleReviews.map((review, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Box
                                    sx={{
                                        padding: '20px',
                                        textAlign: 'center',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Avatar
                                        src={review.image}
                                        alt={review.name}
                                        sx={{
                                            width: '80px',
                                            height: '80px',
                                            margin: '0 auto 20px',
                                        }}
                                    />
                                    <Typography sx={{ color: "black" }} variant="body1" gutterBottom>
                                        {truncateText(review.text, expandedReviews[review.id])}
                                    </Typography>
                                    {review.text.length > 100 && (
                                        <Button onClick={() => toggleExpand(review.id)} size="small">
                                            {expandedReviews[review.id] ? 'Read Less' : 'Read More'}
                                        </Button>
                                    )}
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: "#4D3922ff" }}>
                                        {review.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Arrow Right (only show on larger screens) */}
                {!isMobile && (
                    <Grid item xs={1}>
                        <IconButton
                            onClick={handleNext}
                            disabled={currentIndex + reviewsToShow >= reviews.length}
                            sx={{ visibility: currentIndex + reviewsToShow >= reviews.length ? 'hidden' : 'visible' }}
                        >
                            <ArrowForward />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ReviewShowcase;