import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HeroImage from "../components/HeroImage";
import { useSwipeable } from 'react-swipeable';

// Sample services data (this part remains unchanged)

const servicesData = [
    {
        title: 'Ismerkedő beszélgetés',
        description: 'Előzetes tudás felmérés, Kívánságok, célok megbeszélése, Szabad hely ellenőrzés.',
        price: 'Ingyenes',
        groupPrice: "Ingyenes",
        length: 'Változó',
        image: "." + process.env.PUBLIC_URL + '/firstmeeting.webp'
    },
    {
        title: 'Általános angol',
        description: 'Általános angol oktatás egyéni vagy csoportban.',
        price: '7000 Ft (1 fő)',
        groupPrice: "2 fős csoport: 4000 Ft/fő,\n \n" +
            "            3 fős csoport: 3000 Ft/fő,\n \n" +
            "            4 fős csoport: 2000 Ft/fő",
        length: '50 perc',
        image: "." + process.env.PUBLIC_URL + '/basiceng.webp'
    },
    {
        title: 'Üzleti angol',
        description: 'Üzleti angol oktatás egyéni vagy csoportban.',
        price: '8000 Ft (1 fő)',
        groupPrice: `2 fős csoport: 4500 Ft/fő,
 
            3 fős csoport: 3500 Ft/fő,
 
            4 fős csoport: 2500 Ft/fő`,
        length: '50 perc',
        image: "." + process.env.PUBLIC_URL + '/corpoeng.webp'
    },
    {
        title: 'Fordítás',
        description: 'Külön árajánlat a fordítandó szöveg és a határidő alapján\n' +
            'személyes egyeztetés után.',
        price: 'Külön árajánlat alapján',
        groupPrice: null,
        length: 'Változó',
        image: "." + process.env.PUBLIC_URL + '/translation.webp'
    },
    {
        title: 'Céges angol',
        description: 'Külön árajánlat személyes egyeztetés után.',
        price: 'Egyeztetés után',
        groupPrice: null,
        length: 'Változó',
        image: "." + process.env.PUBLIC_URL + '/businesseng.webp'
    },
    {
        title: 'Perc alapú óra díj',
        description: 'Az általános és speciális nyelvi órák előre egyeztetett\n' +
            'időpontokban kerülnek megtartásra. A perc alapú órák akár az\n' +
            'óra előtt 5 perccel is foglalhatóak. Ez természetesen akkor\n' +
            'lehetséges, ha az adott idősávban nincsen más elfoglaltságom.\n' +
            'Ez kedvező lehet olyanoknak, akik sűrű, de kiszámíthatatlan\n' +
            'munkarend szerint dolgoznak. Ha éppen adódik egy szab fél\n' +
            'órájuk, akkor azt akár angol nyelvű beszélgetéssel is tölthetik.\n' +
            'Természetesen nem csak beszélgetős óra lehet ez a\n' +
            'típusú óra. pl. Előfordulhat, hogy valaki a holnapi vizsga előtt\n' +
            'úgy érzi, hogy egy nyelvtani rész még nincs teljesen a helyén és\n' +
            'kéne egy kis azonnali segítség.',
        price: 'Ára: 140 Ft/perc/1 fő',
        groupPrice: null,
        length: 'Minimum 10, maximum 100 perc.',
        image: "." + process.env.PUBLIC_URL + '/minuteeng.webp'
    },
];

const StyledCard = styled(Card)(({ theme }) => ({
    minWidth: 300,
    maxWidth: 400,
    margin: '0 10px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
}));

const HeadingImage = styled('div')(({ theme }) => ({
    height: '150px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& h5': {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    }
}));

const ServicesPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedIndex, setExpandedIndex] = useState(-1);

    // Media query for detecting screen size
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Adjust the width based on your needs
    const isLargeScreen = useMediaQuery('(min-width:1024px)');
    const visibleCards = isLargeScreen ? 3 : 1;

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex === 0 ? servicesData.length - visibleCards : prevIndex - 1)
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex === servicesData.length - visibleCards ? 0 : prevIndex + 1)
        );
    };

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? -1 : index);
    };

    // Swipe handler for small screens
    const swipeHandler = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const visibleServices = servicesData.slice(currentIndex, currentIndex + visibleCards);

    return (
        <Box sx={{ position: 'relative' }}>
            <HeroImage text={"Szolgáltatások és Árak"} />
            
            {/* Conditionally apply swipe handler based on screen size */}
            <Box {...(isSmallScreen ? swipeHandler : {})} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                
                {/* Conditionally render arrows for larger screens */}
                {!isSmallScreen && (
                    <Button onClick={handlePrev} disabled={currentIndex === 0}>
                        <ArrowBackIosIcon />
                    </Button>
                )}
                
                <Box sx={{ display: 'flex' }}>
                    {visibleServices.map((service, index) => {
                        const isExpanded = expandedIndex === currentIndex + index;
                        const description = isExpanded ? service.description : service.description.slice(0, 100) + '...';

                        return (
                            <StyledCard key={index}>
                                <HeadingImage style={{ backgroundImage: `url(${service.image})` }}>
                                    <Typography sx={{ color: "#b42f37", fontSize: "25px", fontFamily: "Montserrat, serif", fontWeight: "bolder" }}>
                                        {service.title}
                                    </Typography>
                                </HeadingImage>
                                <CardContent>
                                    <Typography sx={{ color: "grey" }} variant="body1">
                                        {description}
                                    </Typography>
                                    {service.description.length > 100 && (
                                        <Button onClick={() => toggleExpand(currentIndex + index)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </Button>
                                    )}
                                    <Typography variant="body2" sx={{ marginTop: 2, color: "black" }}>
                                        <strong>Ár: </strong>{service.price}
                                    </Typography>
                                    {service.groupPrice && (
                                        <Typography variant="body2" sx={{ marginTop: 2, color: "black" }}>
                                            <strong>Csoportos Árak: </strong>{service.groupPrice}
                                        </Typography>
                                    )}
                                    <Typography variant="body2" sx={{ marginTop: 1, color: "black" }}>
                                        <strong>Időtartam: </strong>{service.length}
                                    </Typography>
                                </CardContent>
                            </StyledCard>
                        );
                    })}
                </Box>

                {!isSmallScreen && (
                    <Button onClick={handleNext} disabled={currentIndex === servicesData.length - visibleCards}>
                        <ArrowForwardIosIcon />
                    </Button>
                )}
            </Box>

            <Box sx={{ position: 'absolute', bottom: 0, right: 0, padding: 2 }}>
                <Typography variant="caption" component="div" sx={{ textAlign: 'right' }}>
                    Designed by <a href="https://www.freepik.com" target="_blank" rel="noopener noreferrer">Freepik</a>
                </Typography>
            </Box>
        </Box>
    );
};

export default ServicesPage;