import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Introductory = () => {
    return (
        <Box
            sx={{
                padding: '40px',
                background: 'linear-gradient(135deg, #302F11ff, #4D3922ff)', // Darker gradient
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                maxWidth: '1000px',
                margin: '40px auto',
            }}
        >
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            borderRadius: '8px',
                            overflow: 'hidden', // Ensures the image respects the borderRadius
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Box
                            component="img"
                            src={"." + process.env.PUBLIC_URL + '/introimg.webp'} // Replace with your image URL
                            alt="Introductory"
                            sx={{
                                width: '100%',
                                display: 'block', // Ensure the image doesn't have extra space
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#FDFCE9ff' }}>
                        Kedves Érdeklődő!
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.7, color: '#FDFCE9ff', fontSize: "18px" }}>
                        Néhány szó a szakmai múltamról.
                        Három évtizede tanítok angolt, és még mindig szeretem csinálni. Hobbiként
                        szívszerelemből kezdtem angolt tanulni és 4 év alatt jutottam el a felsőfokú
                        nyelvvizsgáig (tehát nem vagyok nyelvzseni). Fél évet dolgoztam ezután az USA-
                        ban West Virginia államban, ezt követően pedig fél évet Hollandiában.
                        Hazatérve magántanárként kezdtem tanítani. 4 évet tanítottam a Szolnoki
                        Gazdasági Főiskolán (most Debreceni Egyetem), majd 15 évet vállalkozóként
                        dolgoztam, mint szabadúszó angol tanár cégeknél, nyelviskoláknál és
                        magántanítványokkal. 2000-ben mester diplomát szereztem az ELTE angol
                        nyelv és irodalom szakon. 2012-től a Szolnoki Főiskolán (most Debreceni
                        Egyetem) kezdtem angol szaknyelvet és turisztikai tárgyakat tanítani angolul.
                        2017-ben doktoráltam a Szent István (most MATE) Egyetemen Summa Cum
                        Laude értékeléssel. Jelenleg a Debreceni Egyetem adjunktusa vagyok és főként
                        angol üzleti szaknyelvet oktatok. Azt hiszem gazdag tapasztalati és tudásanyag
                        gyűlt össze bennem ezen évek során.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Introductory;
