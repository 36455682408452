import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Box, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeroImage from '../components/HeroImage';

const FaqPage = () => {
    return (
        <Box>
            <HeroImage text={"Gyakori Kérdések"} />
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Tipikus kérdések (Frequently Asked Questions)
            </Typography>

            {/* First FAQ */}
            <Box sx={{ margin: "20px"}}>
            <Typography sx={{ color: "black"}} variant="h5">Felkészüléssel kapcsolatos kérdések</Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Mennyi idő alatt tudok eljutni a középfokú/felső fokú/érettségi/stb. vizsgáig?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Az, hogy egy magántanuló/csoport a jelenleg tudás szintről mennyi idő alatt jut el a célként kitűzött magasabb tudásszintre, 
                            <Typography component="span" sx={{ color: 'red' }}> több tényezőtől is függ.
                            </Typography>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Milyen szinten van most?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Ezt az első alkalommal a szintfelméréssel ki lehet deríteni.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Second FAQ */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Mennyit tud/hajlandó angollal foglalkozni naponta?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Ha valaki önállóan is hajlandó tanulni az órák közötti napokon, az nyilván gyorsabban halad, mint az, aki csak az órákon tud angollal foglalkozni.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    A kitűzött célt mikor tervezi elérni?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Ha valakinek ugyanarról a szintről fél éve van eljutni
                        nyelvvizsgáig, másnak pedig másfél éve akkor nyilvánvalóan más tempót kell diktálni a két diáknak.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Mennyire követi szorosan a tanár instrukcióit?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        A tanulás sikeréhez a tanár és a diák is kell. Aki
                        mindig megcsinálja a megbeszélt feladatokat, az nyilván gyorsabban tud haladni.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Milyen veleszületett nyelvérzéke van?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Minden átlagos képességű ember képes eljutni középfokú (B2)
                        típusú nyelvvizsgáig. De az tény, hogy van, akinek könnyebben megy a nyelvtanulás, mint másoknak.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Mennyire kompatibilis a tanárral?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        A legnagyobb jószándék mellett is előfordul, hogy egy diák úgy
                        érzi, hogy valamiért nem szimpatikus neki egy tanár. Ezzel semmi gond, emberek vagyunk.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            </Box>
            <Box sx={{ margin: "20px" }}>
                <Typography sx={{ color: "black"}} variant="h5">Tanulással kapcsolatos kérdések</Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Melyik a legjobb módszer, hogy megtanuljak angolul?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Typography component="span" sx={{ color: 'red' }}>Nincs legjobb módszer.
                            </Typography>
                            Aki azt állítja van, az inkább marketinges, mint jó tanár. Ha volna egyébként
                            ilyen módszer, akkor már mindenki azt használná. Nézzük, miért nincs egy üdvözítő módszer. Az
                            alkalmazandó módszer sok tényezőtől függ:
                            <ul>
                            <li style={{ fontWeight: "bolder"}}>
                            Mi a célja a nyelvtanulásnak?
                            </li>
                            <span>Szinten tartás? Nyelvvizsga? Nyelvvizsga gyorsan vagy kényelmes tempóban? Szóbeli vagy írásbeli
                            nyelvvizsga? Külföldi munkavállalás? Állásinterjú itthoni munkához? Tanulás külföldön? Céges
                            feladatok jobb ellátása? Stb.</span>
                            <li style={{ fontWeight: "bolder", marginTop: "10px"}}>
                            Milyen ember a nyelvtanuló? (És a tanár!)
                            </li>
                            <span>Egy nagy önbizalommal bíró cégvezetőről van szó? Egy angolt utáló egyetemistáról? Egy introvertált
                            kiskamaszról? Egy extrovertál állandóan csacsogó családanyáról? Kitartó a diák? Általában optimista?
                            Milyen az érdeklődési köre? Stb.</span>
                            <li style={{ fontWeight: "bolder", marginTop: "10px"}}>
                            Mennyi pénzt, időt és energiát tud áldozni a nyelvtanulásra?
                            </li>
                            <span>Nyilvánvalóan más módszertant követel egy olyan diák tanítása, aki heti 1 magán órát tud venni és
                            egy olyan, ugyanolyan képességű, szintről induló, célt ugyanannyi idő alatt elérni akaró diáké, aki heti
                            3 órát tanul tanárral. De más a módszertan akkor is, ha mindketten 1 órát vesznek hetente, de az
                            egyikük könnyen megszólal, mert 90% extrovertált személyiség, míg a másik alig mer megszólalni,
                            mert 90% introvertált típus.
                            Természetesen vannak általános tanulásmódszertani elvek, melyek a legtöbb embernél növelik a
                            hatékonyságot. A módszer az első pár óra után tud „véglegesedni” a tanuló/csoport jellemzőitől
                            függően. Mivel az élet állandó változásokat produkál, a módszer is változhat menet közben.</span>
                            <li style={{ fontWeight: "bolder", marginTop: "10px"}}>
                            Mennyire múlik a siker a tanáron és a diákon?
                            </li>
                            <span><Typography>
                            Ez egy régóta vitatott kérdés. <Typography sx={{ color: "red"}} variant={"span"}>Pontos válasz nem létezik.</Typography> Egy ausztrál szakcikk szerint a következő a
                            diák tanulási sikerében a tényezők aránya: Diák: 50%, Tanár: 30%, Otthoni hatások: 5-10%, Iskolai
                            környezet: 5-10 %, Társak hatása: 5-10%. A cikk iskolai osztályokra vonatkozóan ad becslést
                            <Link sx={{ color: "lightgreen"}} href={"https://research.acer.edu.au/cgi/viewcontent.cgi?article=1003&amp;context=research_conference_2003"}> (cikk). </Link>
                            Arról, hogy egy magánóra keretében a sikerben mekkora a szerepe a tanárnak és diáknak, nem
                            létezik szakirodalom. Szerintem az a lényeg, hogy mindkét fél, tanár és diák is, teljes erőbedobással
                            vegyen részt a tanítás/tanulás nevű közös projektben.
                        </Typography></span>
                            </ul>
                            
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{ margin: "20px" }}>
                <Typography sx={{ color: "black"}} variant="h5">Tipikus kérdések, amikre rögtön van válasz</Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Mi az, amit a tanár garantálni tud?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Amit garantálni tudok a következő: Minden órán a teljes figyelmemet fogja élvezni a tanuló/csoport
                            és tudásom legjavát fogom nyújtani. Az órák jó hangulatúak lesznek, és mindenkinek
                            személyre/csoportra szabottan fogok javasolni tanulási stratégiát, amit, ha szigorúan betart, nem fog
                            elmaradni a siker. Tudom-e garantálni, hogy valaki biztosan átmegy egy vizsgán? Nem. Aki ilyesmit
                            garantál, az egy jós, vagy felelőtlen marketinges. Azt meg tudom mondani, hogy egy diák az órák
                            alapján felkészülnek mondható-e egy megmérettetésre. Hogy sikeren veszi-e majd az akadályt,
                            mondjuk egy nyelvvizsgát, az olyan dolgoktól is függ (pl vizsga stressz), amire a tanárnak semmi
                            befolyása nincs.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Lemondhatom-e az órát?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Typography sx={{ color: "red"}} variant={"span"}>Igen, de</Typography> ha a lemondás az órát megelőző 24 órán belül történik, akkor az óra megtartottnak számít és
                            a teljes óradíjat ki kell fizetni.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Kapok-e tananyagot?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Minden tanítványomnak megpróbálok ingyenesen tananyagot biztosítani. Ez <Typography sx={{ color: "red"}} variant={"span"}>általában sikerül</Typography> is.
                            Mivel azonban, rengeteg féle célja lehet a tanulásnak, előfordulhat, hogy tananyag megvásárlására
                            teszek javaslatot.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Honnan tudhatom, hogy a Vélemények az oldalon valós emberek által írt vélemények.
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Typography sx={{ color: "red"}} variant={"span"}>Gyakorlatilag sehonnan</Typography>. Csak a becsületszavamra lehet hagyatkozni: Tényleg azok. 😊
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Beleszólhatok-e abba, hogy mit és hogyan tanuljunk?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Typography sx={{ color: "red" }} variant={"span"}>Természetesen.</Typography> Nagyon is kívánatos, hogy egy diák megfogalmazza, hogyan szeretne tanulni és mit
                            vár el a tanártól. Ugyanakkor, mindig el fogom mondani a véleményemet, ha úgy érzem, a kérés nem
                            szolgálja a hatékonyságot. Mivel azonban a diák meggyőződése nagyban befolyásolja a tanulás
                            hatékonyságát, a szakmai véleményem egy módszerről lehet, hogy lényegtelen. Ha egy diák úgy érzi,
                            hogy őt az viszi előre legjobban, ha közösen fordítunk cikkeket az órán, akkor ez a meggyőződés és
                            szubjektív érzés simán felülírhatja a tudományos vélekedést az adott módszerről.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Mennyire rugalmas az órák hossza?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Typography sx={{ color: "red" }} variant={"span"}>Alapvetően nem az.</Typography> Az órák a Szolgáltatások/Árak (linkelt) menüpontban megjelölt ideig tartanak,
                            <Typography sx={{ color: "red" }} variant={"span"}> de</Typography> ha valamilyen hirtelen, az óra közben történő halaszhatatlan esemény miatt (pl. szólnak a suliból,
                            hogy beteg lett a gyerek és menni kell) abba kell hagynunk (miattam vagy a diák miatt) az órát, akkor
                            csak a leállásig eltelt időt kell arányosan kifizetni. Ha a diák szimplán csak úgy érzi, hogy szeretné az
                            órát pl. 10 perccel hamarabb befejezni, akkor is a teljes óradíjat kell kifizetni. Ha a diák úgy érzi az óra
                            vége felé, hogy szeretne még angolozni és éppen van is erre szabad idősáv, akkor az óra végétől a
                            Perc alapú óra díj (Szolgáltatások/Árak) lép érvénybe. Hogy ne legyen félreértés abból, hogy kinek
                            hogyan jár az órája, az órát a <Link sx={{ color: "lightgreen"}} href={"https://time.is/"}>Pontos Idő</Link> nyilvános weboldalon található óra szerint tartjuk.
                            Az óra elején mindig elmondom, hogy hány óra hány perc van és azt is, hogy elkezdtük az órát.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Mi történik, ha óra közben elmegy az internet, vagy leromlik a kapcsolat?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Ebben az esetben 5 percig próbáljuk egymást újra elérni. Ha sikerül, akkor az órát annyival tovább
                            tartjuk, ahány perc (max. 5) eltelt az újra kapcsolódással. Ha nem sikerül, akkor 5 perc után azonnal
                            telefonon egyeztetünk. Ilyen esetben  <Typography sx={{ color: "red" }} variant={"span"}> csak a megszakadásig eltelt időt kell arányosan kifizetni.</Typography> 
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Milyen platformon zajlanak az órák?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Typography sx={{ color: "red" }} variant={"span"}>Sok lehetőség van.</Typography> Ezt az első telefonos kapcsolatfelvétel alkalmával megbeszéljük.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            {/* You can continue adding more FAQs here following the same structure */}
        </Container>
        </Box>
    );
};

export default FaqPage;
