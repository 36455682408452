import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// Styled components for AppBar and Links
const StyledAppBar = styled(AppBar)({
  backgroundColor: 'transparent',
  boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(10px)',
  position: 'fixed',
  top: 0,
  width: '100%',
});

const StyledLink = styled(Link)({
  margin: '0 16px',
  color: 'black',
  textDecoration: 'none',
  '&:hover': {
    '& span': {
      transform: 'scale(1.1)',
      transition: 'transform 0.3s ease-in-out',
      display: 'inline-block',
    },
  },
});

const DrawerListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.primary.main, // Use your color palette here
  '& .MuiTypography-root': {
    fontWeight: 'bold',
  },
}));

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)'); // Adjust width for mobile/tablet

  // Toggle drawer for the hamburger menu
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Menu links
  const menuItems = [
    { text: 'Gyakori Kérdések', link: '/about' },
    { text: 'Szolgáltatások és Árak', link: '/services' },
    { text: 'Élérhetőség', link: '/contact' },
  ];

  // Drawer content (hamburger menu)
  const drawerList = () => (
      <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
      >
        <List>
          {menuItems.map((item, index) => (
              <ListItem button key={index} component={Link} to={item.link}>
                <DrawerListItemText primary={item.text} />
              </ListItem>
          ))}
        </List>
      </Box>
  );

  return (
      <StyledAppBar>
        <Toolbar sx={{ px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Logo as a Link to homepage */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/">
              <img src={"." + process.env.PUBLIC_URL + '/logo512.png'} alt="Logo" style={{ height: 40 }} />
            </Link>
          </Box>

          {/* Welcome text (only shown on larger screens) */}
          {!isMobile && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ flexGrow: 0 }} style={{ color: 'black' }}>
                  Welcome to angolozok.hu
                </Typography>
              </Box>
          )}

          {/* Responsive navigation: hamburger menu for mobile, links for desktop */}
          {isMobile ? (
              <>
                <IconButton edge="start" color="orange" aria-label="menu" onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                  <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        justifyContent: 'space-between',
                      }}
                  >
                    <Typography variant="h6" sx={{ color: '#4D3922ff' }}>
                      Menu
                    </Typography>
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {drawerList()}
                </Drawer>
              </>
          ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {menuItems.map((item, index) => (
                    <StyledLink to={item.link} key={index}>
                      <span>{item.text}</span>
                    </StyledLink>
                ))}
              </Box>
          )}
        </Toolbar>
      </StyledAppBar>
  );
};

export default Header;