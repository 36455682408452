import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:768px)'); // Define the variable

    return (
        <Box
            sx={{
                padding: '20px 0',
                backgroundColor: '#302F11ff',
                color: '#FDFCE9ff',
                textAlign: 'center',
                position: 'relative',
                bottom: 0,
                width: '100%',
                marginTop: '40px',
                borderTop: '5px solid #9F885B',
            }}
        >
            <Grid container alignItems="center" justifyContent="center">
                {/* Left Links */}
                <Grid item xs={4}>
                    <Box
                        sx={{
                            display: isMobile ? 'none' : 'flex',
                            justifyContent: 'flex-end',
                            gap: '20px',
                        }}
                    >
                        <Link to="/about" style={{ color: '#FDFCE9ff', textDecoration: 'none' }}>
                            Gyakori Kérdések
                        </Link>
                        <Link to="/contact" style={{ color: '#FDFCE9ff', textDecoration: 'none' }}>
                            Elérhetőség
                        </Link>
                    </Box>
                </Grid>

                {/* Center Logo */}
                <Grid item xs={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80px',
                        }}
                    >
                        <img
                            src={"." + process.env.PUBLIC_URL + '/logo512.png'}
                            alt="Logo"
                            style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }}
                        />
                    </Box>
                </Grid>

                {/* Right Links */}
                <Grid item xs={4}>
                    <Box
                        sx={{
                            display: isMobile ? 'none' : 'flex',
                            justifyContent: 'flex-start',
                            gap: '20px',
                        }}
                    >
                        <Link to="/services" style={{ color: '#FDFCE9ff', textDecoration: 'none' }}>
                            Szolgáltatások és Árak
                        </Link>
                    </Box>
                </Grid>
            </Grid>

            {/* Copyright */}
            <Typography
                variant="body2"
                sx={{
                    marginTop: '20px',
                    color: '#9F885Bff',
                }}
            >
                &copy; {new Date().getFullYear()} angolozok. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;