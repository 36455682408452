import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Snackbar, IconButton, Fade, useMediaQuery } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import HeroImage from "../components/HeroImage";  // hCaptcha component

const ContactPage = () => {
    const [hovered, setHovered] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');  // Check if the screen is smaller than 768px

    useEffect(() => {
        const storedCaptcha = localStorage.getItem('captchaToken');
        if (storedCaptcha) {
            setCaptchaVerified(true);
        }
    }, []);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSnackbarMessage(`${text} copied to clipboard!`);
                setOpenSnackbar(true);
            })
            .catch(() => {
                setSnackbarMessage('Failed to copy.');
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const onHCaptchaVerify = (token) => {
        setCaptchaVerified(true);
        localStorage.setItem('captchaToken', token);  // Store only in localStorage
        console.log("hCaptcha token: ", token);
    };

    return (
        <Box sx={{ maxWidth: '100vw', overflowX: 'hidden' }}>
            <HeroImage text={"Elérhetőség "}></HeroImage>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '60vh',
                    backgroundColor: '#FDFCE9ff',
                    padding: '40px',
                    textAlign: 'center',
                    maxWidth: '100%',
                }}
            >
                <Typography variant="h4" component="h1" sx={{ marginBottom: '20px', color: '#4D3922ff' }}>
                    Hívom a tanárt
                </Typography>

                {!captchaVerified && (
                    <Box sx={{ marginBottom: '20px' }}>
                        <HCaptcha
                            sitekey="6a264f72-813e-4420-ac9a-cf4ab0ac1e58"
                            onVerify={onHCaptchaVerify}
                        />
                    </Box>
                )}

                {captchaVerified && (
                    <>
                        {/* Telephone Button */}
                        <Box
                            sx={{ position: 'relative', display: 'inline-block', marginBottom: '20px' }}
                            onMouseEnter={() => setHovered('tel')}
                            onMouseLeave={() => setHovered(null)}
                        >
                            <Button
                                href="tel:+36203262000"
                                variant="contained"
                                color="primary"
                                startIcon={<PhoneIcon />}
                                sx={{
                                    fontSize: '18px',
                                    padding: '10px 20px',
                                    backgroundColor: '#4D3922ff',
                                    '&:hover': {
                                        backgroundColor: '#9F885Bff',
                                    },
                                }}
                            >
                                TEL: +36 20 326 2000
                            </Button>

                            {/* Conditionally render Copy button */}
                            {!isMobile && (
                                <Fade in={hovered === 'tel'}>
                                    <Button
                                        onClick={() => handleCopy('+36203262000')}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<ContentCopyIcon />}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            left: '110%',
                                            fontSize: '14px',
                                            backgroundColor: 'transparent',
                                            borderColor: '#9F885Bff',
                                            '&:hover': {
                                                borderColor: '#4D3922ff',
                                            },
                                            transition: 'all 0.3s ease-in-out',
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </Fade>
                            )}
                        </Box>

                        {/* Email Button */}
                        <Box
                            sx={{ position: 'relative', display: 'inline-block' }}
                            onMouseEnter={() => setHovered('email')}
                            onMouseLeave={() => setHovered(null)}
                        >
                            <Button
                                href="mailto:guszfraba@gmail.com"
                                variant="contained"
                                color="secondary"
                                startIcon={<EmailIcon />}
                                sx={{
                                    fontSize: '18px',
                                    padding: '10px 20px',
                                    backgroundColor: '#9F885Bff',
                                    '&:hover': {
                                        backgroundColor: '#4D3922ff',
                                    },
                                }}
                            >
                                Email: guszfraba@gmail.com
                            </Button>

                            {/* Conditionally render Copy button */}
                            {!isMobile && (
                                <Fade in={hovered === 'email'}>
                                    <Button
                                        onClick={() => handleCopy('guszfraba@gmail.com')}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<ContentCopyIcon />}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            left: '110%',
                                            fontSize: '14px',
                                            backgroundColor: 'transparent',
                                            borderColor: '#9F885Bff',
                                            '&:hover': {
                                                borderColor: '#4D3922ff',
                                            },
                                            transition: 'all 0.3s ease-in-out',
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </Fade>
                            )}
                        </Box>
                    </>
                )}

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Box>
        </Box>
    );
};

export default ContactPage;
