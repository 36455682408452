import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const HeroImage = ({ text }) => {
  const [blur, setBlur] = useState(0);
  const [animate, setAnimate] = useState(false);

  const handleScroll = () => {
    const image = document.getElementById('hero-image');
    const rect = image.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Calculate how much of the image is visible
    const visibleHeight = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0));
    const visibilityPercentage = visibleHeight / rect.height;

    // Blur more when less of the image is visible
    const blurAmount = Math.max(0, 10 - visibilityPercentage * 10);
    setBlur(blurAmount);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Box
      id="hero-image"
      sx={{
        width: '100%',
        height: '500px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={"." + process.env.PUBLIC_URL + '/heroimg.webp'} // Replace with your image URL
        alt="Hero"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          filter: `blur(${blur}px)`,
          transition: 'filter 0.3s ease',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            color: 'white',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
            transform: animate ? 'translateX(0)' : 'translateX(-100%)', // Animate text
            transition: 'transform 1.2s ease-in-out',
          }}
        >
            {text.split(" ")[0] + " " + text.split(" ")[1]}
        </Typography>
        <Typography
          variant="h3"
          component="h1"
          sx={{
            color: 'white',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
            transform: animate ? 'translateX(0)' : 'translateX(100%)', // Animate text
            transition: 'transform 1.5s ease-in-out',
            marginTop: '10px', // Optional: Add some space between the two lines
          }}
        >
            {text.split(" ")[2]}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroImage;